import * as React from 'react';
import { Helmet } from 'react-helmet';
import ContactUsButton from '../compontents/ContactUsButton/ContactUsButton';
import Layout from '../compontents/Layout/Layout';
import ManagedServicesDetails from '../compontents/ManagedServicesDetails/ManagedServicesDetails';
import ManagedServicesText from '../compontents/ManagedServicesText/ManagedServicesText';
import { contact_us_container } from './managed-services.module.scss';

type ManagedServicesPageProps = {
  location: any;
};

const ManagedServicesPage: React.FC<ManagedServicesPageProps> = ({ location }) => {
  return (
    <Layout location={location}>
      <Helmet>
        <title>Managed Services - Easier Updates, Easier Lives!</title>
      </Helmet>
      <div className="managed-services-container" data-testid="managed-services-container">
        <ManagedServicesText></ManagedServicesText>
        <ManagedServicesDetails></ManagedServicesDetails>
      </div>
      <div className={`${contact_us_container} d-flex align-items-center flex-column p-2`}>
        <div>Contact Us now to get your presence online or build your application.</div>
        <div>
          <ContactUsButton />
        </div>
      </div>
    </Layout>
  );
};

export default ManagedServicesPage;
