import React from 'react';
import { managed_services2 } from './ManagedServicesDetails.module.scss';

const ManagedServicesDetails: React.FC = () => {
    return (
        <div
            className="managed-services-details-container d-flex flex-direction-row"
            data-testid="managed-services-details-container">
            <div className="row">
                <h2 className="d-flex justify-content-center p-2">WE STILL HOST WEBSITES</h2>
                <p className="d-flex justify-content-center p-4">
                    Our legacy at SquareHook was hosting websites and we still do! Contact us now to see what options
                    are available.
                </p>
                <div className="image col-lg-8 col-md-4 p-2">
                    <img
                        src="../../images/managed-services2-png.png"
                        className={`${managed_services2} img-fluid`}
                        data-testid="managed-services-details-image"
                    />
                </div>
                <div className="col-lg-4 col-md-8 p-4" data-testid="managed-services-details-list">
                    <h2>Managed Solutions</h2>
                    <h4>Our Expertise</h4>
                    <ul>
                        <li>Optimize Cost</li>
                        <li>Improve Compliance</li>
                        <li>Strengthen Security</li>
                        <li>Cloud Infrastructure</li>
                        <li>Data Backup</li>
                        <li>Disaster Recovery</li>
                        <li>Multi-Region</li>
                        <li>Automate Redundant Tasks</li>
                        <li>Create or Update CI/CD Pipelines</li>
                        <li>Infrastructure Resiliency</li>
                        <li>Manage Services For Other Projects</li>
                        <li>Setup New Environments</li>
                        <li>Migration or Upgrade</li>
                        <li>Automated Testing</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default ManagedServicesDetails;
