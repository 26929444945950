import React from 'react';
import { managed_services1 } from './ManagedServicesText.module.scss';

const ManagedServicesText: React.FC = () => {
    return (
        <div className="container d-flex flex-direction-row p-2" data-testid="managed-services-text-container">
            <div className="row">
                <div className="managed-services-list col-lg-4 col-md-8 p-2" data-testid="managed-services-text-list">
                    <h2>Managed Services</h2>
                    <p>
                        Most companies focus on a break-fix service model; meaning, when your site encounters a problem,
                        it is given a temporary fix. At SquareHook we go with a different approach. We use an
                        improvement model when it comes to managed solutions for your company. We go through your
                        environment and assess improvements that we could implement. By using this format, we can get
                        ahead of the issues instead of reacting to the problems. We also keep industry standards with
                        continuous monitoring, testing, and reliability.
                    </p>
                </div>
                <div className="col-lg-8 col-md-4 p-2">
                    <img
                        src="../../images/managed-services-png.png"
                        className={`${managed_services1} img-fluid`}
                        data-testid="managed-services-text-image"
                    />
                </div>
            </div>
        </div>
    );
};

export default ManagedServicesText;
